import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { SubmittedFormsComponent } from './submitted/SubmittedForms.component';
import { AdminAuthGuard } from '../auth/login/AdminAuthGuard';
import { Tool } from '../model/auth/login/Tool';
import { INavbarRoute } from '../layout/navbar/INavbarRoute';
import { Brand } from '../model/auth/login/Brand';
import { AskTheExpertComponent } from './experts/questions/AskTheExpert.component';
import { ContactSubmissionsComponent } from './contactUs/ContactSubmission.component';
import { AdminUptimeComponent } from './platform/admin-uptime/AdminUptime.component';
import { AdminCapacityComponent } from './platform/admin-capacity/AdminCapacity.component';
import { AdminPerformanceComponent } from './platform/admin-performance/AdminPerformance.component';
import { FinancialComponent } from './financial/Financial.component';
import { WaitingTransactionsComponent } from './publishing/waitingTransactions/WaitingTransactions.component';
import { PriorityComponent } from './publishing/priority/Priority.component';
import { ItemComponent } from './publishing/item/Item.component';
import { ExecutionTimeComponent } from './publishing/executionTime/ExecutionTime.component';
import { AdminTestResultsComponent } from './testResults/AdminTestResults.component';
import { ElearningComponent } from './elearning/Elearning.component';

export const ReportRoutes: (INavbarRoute | Route)[] = [
    {
        navbarName: 'Contacts',
        path: 'contacts',
        component: ContactSubmissionsComponent,
        data: { brand: Brand.FemCare },
        canActivate: [AdminAuthGuard],
        requiredAccess: {
            brand: [Brand.FemCare]
        }
    },
    {
        navbarName: 'Expert',
        path: 'expert',
        component: AskTheExpertComponent,
        canActivate: [AdminAuthGuard],
        requiredAccess: {
            tool: [Tool.Expert]
        }
    },
    {
        navbarName: 'Financial',
        path: 'financial',
        component: FinancialComponent,
        canActivate: [AdminAuthGuard],
        requiredAccess: {
            tool: [Tool.FinancialReports]
        }
    },
    {
        navbarName: 'Platform',
        path: 'platform',
        canActivate: [AdminAuthGuard],
        children: [
            {
                navbarName: 'Capacity',
                path: 'capacity',
                component: AdminCapacityComponent,
                canActivate: [AdminAuthGuard],
                requiredAccess: {
                    tool: [Tool.Capacity]
                }
            },
            {
                navbarName: 'Performance',
                path: 'performance',
                component: AdminPerformanceComponent,
                canActivate: [AdminAuthGuard],
                requiredAccess: {
                    tool: [Tool.PerformanceUptime]
                }
            },
            {
                navbarName: 'Uptime',
                path: 'uptime',
                component: AdminUptimeComponent,
                canActivate: [AdminAuthGuard],
                requiredAccess: {
                    tool: [Tool.PerformanceUptime]
                }
            }
        ]
    },
    {
        navbarName: 'Publishing',
        path: 'publishing',
        requiredAccess: {
            tool: [Tool.PublishingStatistics]
        },
        children: [
            {
                navbarName: 'Waiting Transactions',
                path: 'waiting-transactions',
                component: WaitingTransactionsComponent,
                canActivate: [AdminAuthGuard],
                requiredAccess: {
                    tool: [Tool.PublishingStatistics]
                }
            },
            {
                navbarName: 'Priority',
                path: 'priority',
                component: PriorityComponent,
                canActivate: [AdminAuthGuard],
                requiredAccess: {
                    tool: [Tool.PublishingStatistics]
                }
            },
            {
                navbarName: 'Item',
                path: 'item',
                component: ItemComponent,
                canActivate: [AdminAuthGuard],
                requiredAccess: {
                    tool: [Tool.PublishingStatistics]
                }
            },
            {
                navbarName: 'Execution Times',
                path: 'execution-times',
                component: ExecutionTimeComponent,
                canActivate: [AdminAuthGuard],
                requiredAccess: {
                    tool: [Tool.PublishingStatistics]
                }
            }
        ]
    },
    {
        navbarName: 'Submitted',
        path: 'submitted',
        component: SubmittedFormsComponent,
        canActivate: [AdminAuthGuard],
        requiredAccess: {
            tool: [Tool.ActivityTracking]
        }
    },
    /*{
        navbarName: 'Automation',
        path: 'automation',
        canActivate: [AdminAuthGuard],
        fullWidth: true,
        children: [
            {
                navbarName: 'Dashboard',
                path: 'dashboard',
                component: AdminTestResultsComponent,
                canActivate: [AdminAuthGuard],
                fullWidth: true,
                requiredAccess: {
                    tool: [Tool.Reports]
                }
            },
            {
                path: ':domain/:website',
                component: AdminTestResultsComponent,
                canActivate: [AdminAuthGuard],
                fullWidth: true,
                requiredAccess: {
                    tool: [Tool.Reports]
                }
            },
            {
                path: ':domain/:website/:id',
                component: AdminTestResultsComponent,
                canActivate: [AdminAuthGuard],
                fullWidth: true,
                requiredAccess: {
                    tool: [Tool.Reports]
                }
            }
        ]
    },*/
    {
        navbarName: 'E-learning',
        path: 'elearning',
        component: ElearningComponent,
        canActivate: [AdminAuthGuard],
        requiredAccess: {
            // TODO requiredAccess
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(ReportRoutes)],
    exports: [RouterModule]
})
export class ReportRoutingModule {}
